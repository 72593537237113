import all from 'src/config/master.csv';
import flat from 'flat';

window.BW_WHO_COVID_DATA = window.BW_WHO_COVID_DATA || {};

const object = all.reduce(
	(final, next) => ({ ...final, [next.key]: next.value }),
	{}
);

window.BW_WHO_COVID_DATA = flat.unflatten(object);
